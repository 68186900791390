import {FC, lazy, Suspense, useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {isEmpty, isNotEmpty, newDateUserTimezoneOffset, newDateUserTimezoneOffsetMinus, WithChildren} from '../../_metronic/helpers'
//import ChantPageWrapper from '../pages/layout-builder/ChantPageWrapper'
import { AuthorizedFunction } from '../modules/auth/components/AuthorizedFunction'
import PesquisaTemploPage from '../modules/app/management/PesquisaTemploPage'
import TemploPage from '../modules/accounts/TemploPage'
import { useKeycloak } from '@react-keycloak/web'
import { getEscalaByTemplo, getSimpleMediumByEmail } from '../modules/app/management/users-list/core/_requests'
import { useQuery } from 'react-query'
import { toast, ToastContainer } from 'react-toastify'
import { EscalaDevas, Medium, Templo } from '../modules/app/management/users-list/core/_models'
import SalaDevasPage from '../modules/app/management/SalaDevasPage'
import { escape } from 'querystring'
import { array } from 'yup'
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker'
import moment from 'moment'

registerLocale('ptBR', ptBR);

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const MediumPage = lazy(() => import('../modules/accounts/MediumPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/app/chat/ChatPage'))
  const FalangePage = lazy(() => import('../modules/falange/FalangePage'))
  const AdjuntoPage = lazy(() => import('../modules/adjunto/AdjuntoPage'))
  const LegiaoPage = lazy(() => import('../modules/legiao/LegiaoPage'))
  const PesquisaMediumPage = lazy(() => import('../modules/app/management/PesquisaMediumPage'))

  const { keycloak, initialized } = useKeycloak();
  const is_admin = AuthorizedFunction(['admin-sistema']);
  const is_autorizado = AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']);

  //valida cadastro usuário na base
  var email_login = keycloak?.tokenParsed?.email;

  const {
    data: usuario,
  } = useQuery(
    `user-${email_login}`,
      () => {
        if (email_login === undefined) {
          return null;
        }
        var has_message = '';
        var error: boolean = false;
        var escala_valida: boolean = false;
        var is_presidente: boolean = false;

        validarAcesso(email_login, has_message, error, is_presidente, escala_valida)
          .then((array) => {
            validarTemploAtivo(array).then((array) => {
              validarEscalado(array).then((array) => {
                validarEscala(array).then((array) => {
                  lancarError(array);
                })
              })
            })
          })
      },
      {
        onError: (err) => {
          console.error(err)
        },
      }
    );

  function validarAcesso(email_login: string, has_message: string, error: boolean, is_presidente: boolean, escala_valida: boolean) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
          getSimpleMediumByEmail(email_login).then((user) => {
            if(isEmpty(user?.id_medium)) {
              has_message = 'Usuário sem email cadastrado! Entre em contato com Administrador'
              error = true;
            }
            if(!error && user?.in_filho_devas !== 1) {
              has_message = 'Usuário não é Filho(a) de Devas!'
              error = true;
            }

            if(user?.id_medium && user?.templo?.in_templo_ativo == 1 && user?.templo?.id_medium_presidente === user?.id_medium) {
              is_presidente = true;
            }
            console.log("1: VALIDOU ACESSO")
            resolve([user, escala_valida, has_message, error, is_presidente]);
          });
        }, 1000);
    });
  }

  //[user, escala_valida, has_message, error, is_presidente]
  function validarTemploAtivo(elements: any) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!elements[3] && elements[0]?.templo?.in_templo_ativo != 1) {
          elements[2] = 'Templo está INATIVO! Entre em contato com Administrador';
          elements[3] = true;
          console.log("2: VALIDOU TEMPLO: INATIVO");
        }
        console.log("2: VALIDOU TEMPLO: ATIVO");
        resolve([elements[0], elements[1], elements[2], elements[3], elements[4]]);
      }, 1000);
    });
  }

  //[user, escala_valida, has_message, error, is_presidente]
  function validarEscalado(elements: any) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        getEscalaByTemplo(elements[0]?.templo?.id_templo || 0).then((res) => {
          console.log("3: VALIDOU ESCALADO")
          resolve([elements[0], elements[1], elements[2], elements[3], elements[4], res]);
        });
      }, 1000);
    });
  }

  //[user, escala_valida, has_message, error, is_presidente, escalas]
  function validarEscala(elements: any) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let escalaFound = elements[5].data.filter((escala:EscalaDevas) => escala.devas_escalado?.id_medium == elements[0]?.id_medium).map((escala:EscalaDevas) => {
          if (escala) {
            
            var compareDate = moment(moment(), "DD/MM/YYYY");
            var startDate   = moment(newDateUserTimezoneOffsetMinus(escala.init_date), "DD/MM/YYYY");
            var endDate     = moment(newDateUserTimezoneOffsetMinus(escala.end_date), "DD/MM/YYYY");

            if(compareDate.isBefore(startDate) || compareDate.isAfter(endDate)){
              elements[1] = false;
              console.log("ESCALA FORA DE DATA")
            } else {
              elements[1] = true;
              console.log("ESCALA VALIDA")
            }
          } else {
            console.log("DEVAS NAO ESCALADO")
          }
        })
        console.log("4: VALIDOU ESCALA")
        resolve([elements[0], elements[1], elements[2], elements[3], elements[4], elements[5]]);
      }, 1000);
    });

  }

  //[user, escala_valida, has_message, error, is_presidente, escalas]
  function lancarError(elements: any) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('escala_valida: ', elements[1]);
        //validar escala somente templo mae
        if(!elements[3] && !elements[1] && elements[0]?.templo?.id_templo === 1) {
         elements[2] = 'Usuário Devas não escalado!'
         elements[3] = true;
        }

        if (elements[3] && !is_autorizado && !elements[4]) {
          creatToastError(elements[2]);
          setTimeout(() => {
            keycloak.logout();
            localStorage.setItem('key_id_templo', '');
            localStorage.setItem('key_id_medium_login', '');
          }, 3000)
        } else {
          //seta o templo
          localStorage.setItem('key_id_templo', is_autorizado ? '1' : (elements[0]?.templo?.id_templo + ''));
          localStorage.setItem('key_id_medium_login', is_autorizado ? keycloak.tokenParsed?.email : elements[0]?.id_medium);
        }
        console.log("5: TRATOU ERRO")
        resolve([elements[0], elements[1], elements[2], elements[3], elements[4], elements[5]]);
      }, 1000);
    });
  }

  const creatToastError = (has_message: string) => {
    toast.error(has_message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }

  return (
    <>
    <Routes>
      <Route element={<MasterLayout />}>
       
       {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/app/dashboard' />} />
        
        {/* Pages */}
        <Route path='app/dashboard' element={<DashboardWrapper />} />
         
        {/*<Route path='emissao' element={<ChantPageWrapper />} />*/}
        <Route path='menu-test' element={<MenuTestPage />} />
        
        {/* Lazy Modules */}

        <Route
          path='cadastro/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        
        <Route
          path='cadastro/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='cadastro/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />

        {AuthorizedFunction(['operacional-devas']) &&
          <Route
            path='app/enroll/medium/:number/*'
            element={
              <SuspensedView>
                <MediumPage />
              </SuspensedView>
            }
          />
        }

        {AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']) &&
          <Route
            path='app/enroll/templo/:number/*'
            element={
              <SuspensedView>
                <TemploPage />
              </SuspensedView>
            }
          />
        }

      {AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']) &&
          <Route
            path='app/enroll/templo/:number/*'
            element={
              <SuspensedView>
                <TemploPage />
              </SuspensedView>
            }
          />
        }

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        
        {AuthorizedFunction(['operacional-devas']) &&
          <Route
            path='app/management/medium/*'
            element={
              <SuspensedView>
                <PesquisaMediumPage />
              </SuspensedView>
            }
          />
        }

        {AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']) &&
          <Route
            path='app/management/templo/*'
            element={
              <SuspensedView>
                <PesquisaTemploPage />
              </SuspensedView>
            }
          />
        }

        {AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']) &&
          <Route
            path='app/management/devas/*'
            element={
              <SuspensedView>
                <SalaDevasPage />
              </SuspensedView>
            }
          />
        }

         <Route
            path='app/management/falange/*'
            element={
              <SuspensedView>
                <FalangePage />
              </SuspensedView>
            }
          />

          <Route
            path='app/management/adjunto/*'
            element={
              <SuspensedView>
                <AdjuntoPage />
              </SuspensedView>
            }
          />

          <Route
            path='app/management/legiao/*'
            element={
              <SuspensedView>
                <LegiaoPage />
              </SuspensedView>
            }
          />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      
      </Route>
    </Routes>
    <ToastContainer autoClose={2000}/>
    </>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
