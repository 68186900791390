import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    TarefasWidget,
    NovosTemplosWidget,
    VisaoGeralWidget,
    AprovacoesWidget,
    StatisticsWidget5,
    AlteracoesFichasWidget,
} from '../../../_metronic/partials/widgets'
import { AuthorizedFunction } from '../../modules/auth/components/AuthorizedFunction'

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]} description='Sala dos Devas'>
        Principal
      </PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='magnifier'
            color='info'
            iconColor='white'
            title='Pesquisa de Médiuns'
            titleColor='white'
            description='Ficha, Emissão, Classificação'
            descriptionColor='white'
            navigateTo='/app/management/medium/search'
          />
        </div>

{/* 
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='sun'
            color='info'
            iconColor='white'
            titleColor='white'
            descriptionColor='white'
            title='Adjuntos'
            description='Adjunto, Chamada'
            navigateTo='/app/management/adjunto/raiz'
          />
        </div>
  */ }
        {AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']) &&  <div className='col-xl-4'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='geolocation'
              color='info'
              iconColor='white'
              title='Templos do Amanhecer'
              titleColor='white'
              descriptionColor='white'
              description='Presidentes, Termos, Estatuto'
              navigateTo='/app/management/templo/search'
            />
          </div>
        }

        {AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']) &&  <div className='col-xl-4'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='flag'
              color='info'
              iconColor='white'
              title='Sala dos Devas'
              titleColor='white'
              descriptionColor='white'
              description='Cadastro, Escala'
              navigateTo='/app/management/devas/escala'
            />
          </div>
        }

{/* 
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='moon'
            color='warning'
            iconColor='white'
            titleColor='white'
            descriptionColor='white'
            title='Falanges Missionárias'
            description='Primeiras (os), Chamada, Adjuntos de Apoio'
            navigateTo='/app/management/falange/missionarias'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='pointers'
            color='warning'
            iconColor='white'
            titleColor='white'
            descriptionColor='white'
            title='Escalas de Trabalho'
            description='Escalas, Comandantes, Grupos'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='calendar'
            color='warning'
            iconColor='white'
            title='Calendário Oficial'
            titleColor='white'
            descriptionColor='white'
            description='Datas, Consagrações'
          />
        </div>
*/}
      </div>
      {/* end::Row */}

      {/* begin::Row 
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col 
        <div className='col-xl-4'>
          <VisaoGeralWidget
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
         end::Col */}

        {/* begin::Col 
        <div className='col-xl-4'>
          <AprovacoesWidget className='card-xl-stretch mb-xl-8' chartColor='danger' chartHeight='225px' />
        </div>
         end::Col */}

        {/* 
        <div className='col-xl-4'>
          <TarefasWidget className='card-xl-stretch mb-5 mb-xl-8' />
        </div>

        
        <div className='col-xl-4'>
          <MixedWidget8
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='danger'
            chartHeight='150px'
          />
        </div>
       
      </div> */}
      {/* end::Row */}

      {/* begin::Row 
      <div className='row gy-5 g-xl-8'>
        
        {/* begin::Col 
        <div className='col-xxl-4'>
          <NovosTemplosWidget className='card-xxl-stretch' />
        </div>
        end::Col */}

        {/* begin::Col 
        <div className='col-xxl-8'>
          <DevasOnlineWidget className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
        
      </div> */}
      {/* end::Row */}

      {/* begin::Row 
      <div className='row g-5 g-xl-8'>
        
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-xl-8' />
        </div>

        <div className='col-xl-4'>
          <ClassificacaoTimeline medium={{}} className='card-xl-stretch mb-xl-8' />
        </div>

        
      </div>
      end::Row */}

    
      { AuthorizedFunction(['admin-sistema', 'autorizador-cadastro']) && <div className='g-5 gx-xxl-8'>
          <AlteracoesFichasWidget className='' />
        </div>
      }
       
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
